import React from "react"

import { Layout, SEO } from "../components/Layout"

const initialState = {
  fullName: { value: "", error: false },
  email: { value: "", error: false },
  phone: { value: "", error: false },
  message: { value: "", error: false },
}

const Contact = () => {
  const [submitted, setSubmitted] = React.useState(false)
  const [state, setState] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target

    setState({
      ...state,
      [name]: { value },
    })
  }

  const onSubmit = () => {
    // validate

    const fields = {}
    Object.keys(state).reduce((acc, cur) => {
      const field = state[cur]
      if (cur === "email") {
        field.error = !/.+@.+\..+/.test(field.value)
      } else {
        field.error = field.value === ""
      }
      acc[cur] = field
      return acc
    }, fields)
    setState(fields)

    const hasError = Object.keys(state).filter(field => {
      return state[field].error
    })
    if (hasError.length) {
      console.log(state)
    } else {
      // send email
      const params = {
        fullName: state.fullName.value,
        email: state.email.value,
        phone: state.phone.value,
        message: state.message.value,
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      }
      fetch(
        "https://getform.io/f/4048cb7e-b19e-47ab-a5c5-3f4e9e976cf7",
        requestOptions
      )
        .then(response => response.json())
        .then(data => console.log("Email sent!"))

      setSubmitted(true)

      setState({
        fullName: { value: "", error: false },
        email: { value: "", error: false },
        phone: { value: "", error: false },
        message: { value: "", error: false },
      })
    }
  }

  return (
    <Layout>
      <SEO title="Contact Us" />

      <div className="relative max-w-screen-xl mx-auto bg-white">
        <div className="relative mx-auto lg:grid lg:grid-cols-5">
          <div className="px-4 py-16 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 header-underline sm:text-3xl sm:leading-9">
                Get in touch
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                While this website provides general information, it does not
                constitute legal advice. The best way to get guidance on your
                specific legal issue is to contact us directly. To schedule a
                meeting, please call the firm or complete the intake form below.
              </p>
              <dl className="mt-8 text-base leading-6 text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd className="flex">
                    <svg
                      fill="none"
                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                      <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                    <span className="ml-3">
                      <a
                        href="https://goo.gl/maps/c2XR6k9g9Ds2se8k9"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <p>401 East Jackson Street</p>
                        <p>Suite 2340</p>
                        <p>Tampa, FL 33602</p>
                      </a>
                    </span>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    <span className="ml-3">
                      <a href="tel:+18134287535">+1 (813) 428-7535</a>
                    </span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-3">
                      <a
                        href="mailto:kevin@rudinlegal.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        kevin@rudinlegal.com
                      </a>
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            {submitted && (
              <div className="px-4 py-6 mb-4 font-bold text-center text-green-600 bg-green-100 rounded">
                Thank you for contacting us, we will respond shortly.
              </div>
            )}
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                name="contact"
                className="grid grid-cols-1 row-gap-6"
                data-netlify="true"
              >
                <div>
                  <label htmlFor="fullName" className="sr-only">
                    Full name
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      onChange={onChange}
                      value={state.fullName.value}
                      id="fullName"
                      name="fullName"
                      className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                      placeholder="Full name"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      onChange={onChange}
                      value={state.email.value}
                      id="email"
                      name="email"
                      type="email"
                      className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      onChange={onChange}
                      value={state.phone.value}
                      id="phone"
                      name="phone"
                      className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <textarea
                      onChange={onChange}
                      value={state.message.value}
                      id="message"
                      name="message"
                      rows="4"
                      className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out form-input"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <div className="">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      onClick={onSubmit}
                      className="inline-flex justify-center px-6 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-secondary-600 hover:bg-secondary-500 focus:outline-none focus:border-secondary-700 focus:shadow-outline-secondary active:bg-secondary-700"
                    >
                      Submit
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
